html {
  overflow-y: scroll;
}

.employee-container .app_box_appla {
  position: absolute;
  width: calc(100% - 30px);
  top: 97%;
  height: 3%;
  transition: all .3s;
  -moz-transition: .3s all;
  -webkit-transition: all .3s;
  -o-transition: .3s all;
  background-color: #003862;
}

.employee-container .app_box {
  display: flex;
  align-items: center;
  height: 230px;
  text-align: center;
  justify-content: center;
}

.employee-container .app_box img {
  position: relative;
  z-index: 9;
  height: 30%;
  margin: auto;
}

.employee-container .app_box_appla {
  position: absolute;
  width: calc(100% - 30px);
  top: 97%;
  height: 3%;
  transition: 0.3s all;
  -moz-transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  background-color: #003862;
}


.employee-container .app-version {
  color: #80808036;
  font-size: 13px;
}

.employee-container .nav-link {
  border: solid 1px #d0d0d0;
  border-bottom: solid 1px #fff;
  background-color: #fff;
  margin-bottom: -1px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 5px 18px 7px 18px;
  display: inline-block;
  color: #004071;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

.employee-container .table .thead-dark th {
  font-weight: 400;
  color: #fff;
  background-color: #004980;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  border: 2px solid #fff;
  border-bottom: none;
}

.employee-container table {
  margin-top: 24px;
}

.employee-container table tr {
  font-size: 14px;
  cursor: pointer;
}

.employee-container .buttons-wrapper .add-region-button,
.employee-container .buttons-wrapper .add-department-button,
.employee-container .buttons-wrapper .add-position-button,
.employee-container .buttons-wrapper .add-employee-button {
  height: 45px;
  margin-top: 15px;
  background-color: #0191c1;
  display: block;
  padding: 8px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.employee-container .buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.employee-container .submit-wrapper {
  justify-content: flex-end;
  display: flex;
}

.employee-container .cancel-button {
  height: 45px;
  margin-top: 15px;
  background-color: #949494;
  display: block;
  padding: 0 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: 2px solid transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .2s;
  -moz-transition: .2s all;
  -webkit-transition: all .2s;
  -o-transition: .2s all;
}

.employee-container .submit-wrapper .cancel-button {
  margin-right: 16px;
}

.employee-container .submit-button,
.employee-container .app-button {
  height: 45px;
  margin-top: 15px;
  background-color: #0191c1;
  display: block;
  padding: 0px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.employee-container p {
  font-weight: 700;
  color: #004071;
  font-size: 2.1rem;
  margin-bottom: 20px;
}

.employee-container input,
.employee-container select {
  height: 45px !important;
  font-size: 14px;
  border: 1px solid #dee2e6;
}

.employee-container label {
  color: #004071 !important;
  font-size: .775rem !important;
  font-weight: 700 !important;
}

.employee-container .error-message {
  color: #c51717;
  margin-bottom: 10px;
  font-size: .775rem !important;
}

.employee-container .toast {
  border-radius: 12px;
  height: 64px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  bottom: 24px;
  right: 24px;
  padding: 24px;
  box-shadow: 1px 4px 8px 2px #a1a1a1;
  opacity: 1;
  position: absolute;
  top: -15px;
  right: 17px;
}

.employee-container .toast.toast-error {
  background: #d1263b;
}

.employee-container .toast.toast-success {
  background: #17b34b;
}

.employee-container .employee-collapse {
  margin-bottom: 17px;
}

.employee-container .avatar-wrapper {
  width: 50px;
  height: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.employee-container .table td,
.table th {
  vertical-align: middle;
}

.employee-container .avatar-cropper {
  border: solid 1px #36393F;
  min-height: 100px;
  max-height: 300px;
  width: 100%;
  background: white;
}

.employee-container .cropper-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #0b0b0bc9;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-container .cropper-wrapper .cropper-content {
  width: 400px;
  background: white;
}

.employee-container .input-file {
  display: none;
}

.employee-container .form-avatar {
  position: relative;
}

.employee-container .form-avatar-remove-icon {
  position: absolute;
  top: 0;
  right: -38px;
  cursor: pointer;
}

.employee-container .clear-filter-icon-wrapper {
  cursor: pointer;
}

.employee-container .is-invisible {
  background: #ffe4e4;
}